import React from "react";
import Helmet from "react-helmet";

interface Props {
  description: string;
  lang: [];
  meta?: {};
  image: string;
  title: string;
  pathname: string;
}

const SEO = ({
  description,
  lang,
  image: metaImage,
  title,
  pathname
}: Props) => {
  //   const { site } = useStaticQuery(
  //     graphql`
  //       query {
  //         site {
  //           siteMetadata {
  //             title
  //             description
  //             author
  //             keywords
  //             siteUrl
  //           }
  //         }
  //       }
  //     `
  //   );

  const metaDescription = description;
  const image = metaImage || null;
  const canonical = pathname ? `www.emiliemartinez.com${pathname}` : null;
  const language = lang ? lang[0] : "EN";
  const metaTitle = pathname === "/" ? "Emilie Martinez | EM Marketing" : title;
  const titleTemplate = pathname === "/" ? "" : `%s | EM Marketing`;

  return (
    <Helmet
      htmlAttributes={{
        language
      }}
      title={metaTitle}
      titleTemplate={titleTemplate}
      link={
        canonical
          ? [
              {
                rel: "canonical",
                href: canonical
              }
            ]
          : []
      }
      meta={
        [
          {
            name: `description`,
            content: metaDescription
          },
          // {
          //   name: 'keywords',
          //   content: site.siteMetadata.keywords.join(',')
          // },
          {
            property: `og:title`,
            content: title
          },
          {
            property: `og:description`,
            content: metaDescription
          },
          {
            property: `og:type`,
            content: `website`
          },
          {
            name: `twitter:creator`,
            content: "Root Home Services"
          },
          {
            name: `twitter:title`,
            content: title
          },
          {
            name: `twitter:description`,
            content: metaDescription
          }
        ].concat(
          metaImage
            ? [
                {
                  property: "og:image",
                  content: image
                },
                // {
                //   property: 'og:image:width',
                //   content: metaImage.width
                // },
                // {
                //   property: 'og:image:height',
                //   content: metaImage.height
                // },
                {
                  name: "twitter:card",
                  content: "summary_large_image"
                }
              ]
            : [
                {
                  name: "twitter:card",
                  content: "summary"
                }
              ]
        )
        // .concat(meta)
      }
    />
  );
};

export default SEO;
