/** @jsx jsx */
import { jsx } from "@emotion/core";
import { FC } from "react";
import { graphql } from "gatsby";
import Markdown from "react-markdown";

import { Text, Box, Link } from "rebass";
import MainContainer from "../../ui-components/container/container";

interface Props {
  className?: string;
  id?: string;
  data: {
    title: string;
    button: {
      slug: string;
      text: string;
      color: string;
      isButton: boolean;
    };
  };
  titleAs?: "h1" | "h2";
  subtitleAs?: "h1" | "h2";
  css?: any;
}

export const query = graphql`
  fragment Prefooter on ContentfulPrefooter {
    title
    button {
      slug
      text
      color
      isButton
    }
    internal {
      type
    }
  }
`;

const Prefooter: FC<Props & JSX.IntrinsicElements["div"]> = ({
  className = "",
  id = "",
  titleAs = "h1",
  subtitleAs = "h2",
  data,
  ...rest
}) => {
  const { title, button } = data;

  return (
    <Box bg="primary" id="contact" py={[4]}>
      <MainContainer>
        <Text as="h2" color="white" variant="subheading">
          {title}
        </Text>
        <Link
          href="mailto:hello@emiliemartinez.com"
          key={button.text}
          margin="auto"
          mr="0"
          variant="buttonPrimary"
        >
          {button.text}
        </Link>
      </MainContainer>
    </Box>
  );
};

export default Prefooter;
